import { formatDate } from "./dateUtils.mjs"

/**
 * @param {number} ms Time to sleep in milliseconds.
 * @returns A promise resolving after the given time in milliseconds has passed.
 */
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * "users" => "user"
 * "batches" => "batch"
 * "time-entries" => "time-entry"
 * @param {string} name
 * @returns The singular version of the input string
 */
function toSingular(name) {
  if (!name) return
  let result = name
  if (name.toLowerCase().endsWith("ies")) {
    // movies is used in the getting-started tutorial. Other exception might have to be added later
    if (name.toLowerCase() === "movies") result = name.replace(/ies/i, "ie")
    else result = name.replace(/ies$/i, "y")
  } else if (name.toLowerCase().endsWith("ches")) result = name.replace(/ches$/i, "ch")
  else if (name.toLowerCase().endsWith("xes")) result = name.replace(/xes$/i, "x")
  else if (name.toLowerCase().endsWith("s")) result = name.substring(0, name.length - 1) // Remove last "s"
  if (name.toUpperCase() === name) result = result.toUpperCase()
  return result
}

/**
 * "user" => "users"
 * "batch" => "batches"
 * "time-entry" => "time-entries"
 * @param {string} name
 * @returns The plural version of the input string
 */
function toPlural(name) {
  if (!name) return
  let result
  if (name.toLowerCase().endsWith("ch")) result = name.replace(/ch$/i, "ches")
  else if (name.toLowerCase().endsWith("x")) result = name.replace(/x$/i, "xes")
  else if (name.toLowerCase().endsWith("y")) result = name.replace(/y$/i, "ies")
  else if (name.toLowerCase().endsWith("s")) result = name
  else result = name + "s"
  if (name.toUpperCase() === name) result = result.toUpperCase()
  return result
}

/**
 * "ExternalPerson" => "external-person"
 * @param {string} name
 * @returns The dashed version of the input string
 */
function toDashed(name) {
  if (!name) return
  const lower = name.toLowerCase()
  let dashed = lower[0]
  for (let i = 1; i < lower.length; i++) {
    if (name[i] === lower[i]) dashed += lower[i]
    else dashed += "-" + lower[i]
  }
  return dashed
}

function computePersonName(first, last) {
  if (!first) return last?.trim()
  if (!last) return first?.trim()
  return first?.trim() + " " + last?.trim()
}

function chunk(array, nb = 100, options = {}) {
  const { stringSeparator = "," } = options || {}

  const chunks = []
  let index = 0
  if (typeof array[0] === "string" && stringSeparator) {
    // array of string => concat
    for (let i = 0; i < array.length; i++) {
      if (!chunks[index]) chunks[index] = array[i]
      else chunks[index] += stringSeparator + array[i]
      if (i % nb === nb - 1) index++
    }
  } else {
    // => push
    for (let i = 0; i < array.length; i++) {
      if (!chunks[index]) chunks[index] = []
      chunks[index].push(array[i])
      if (i % nb === nb - 1) index++
    }
  }
  return chunks
}

const validationRegExps = {
  phoneRegExp:
    /^(((\+|00)3\d[ _.-]?\d|(0[1-9]))((?:[ _.-]?(\d{2})){4}))$|^((\+|00)[12456789][\d _.-]{7,14})$|^(\+\(\d{1,3}\)[\d _.-]{7,9})$|^[\d _.-]{9,12}$/,
  // https://en.wikipedia.org/wiki/Email_address#Local-part
  emailRegExp:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+([a-zA-Z][-]{0,1}){2,}))$/,
  urlRegExp: /(http(s)?:\/\/)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
}

/**
 * @param {string} name
 * @param {boolean} firstTwoAndLastOne to return first 2 letters + the last letter
 * @returns The trigram (3 letters) version of the input string
 */
function toTrigram(name, firstTwoAndLastOne) {
  if (!name) return

  const _name = name.replaceAll(".", "").replaceAll(",", "").replaceAll("-", "").replace(/[0-9]/g, "").trim().toUpperCase()
  const words = _name.split(" ")

  // 3 words and more => Get first letter of the first 3 words (DOCTO PHONE PARIS => DPP)
  if (words.length > 2)
    return words
      .slice(0, 3)
      .map(word => word.substring(0, 1))
      .join("")

  // 2 words + firstTwoAndLastOne => 1st 2 letters + last letter of the 1st word (DOCTO PHONE => DOO)
  if (words.length === 2 && firstTwoAndLastOne) return words[0].substring(0, 2) + words[0].slice(-1)

  // 2 words => 1st 2 letters of the 1st word + last letter of the 2nd word (DOCTO PHONE => DOE)
  if (words.length === 2) return words[0].substring(0, 2) + words[1].substring(0, 1)

  // 1 word => 1st 2 letters + last letter (DOCTOLIB => DOB)
  if (firstTwoAndLastOne) return _name.substring(0, 2) + _name.slice(-1)

  // 1 word => 1st 3 letters (DOCTOLIB => DOC)
  return _name.substring(0, 3)
  //TODO : Should special characters be managed? ("GLINCHE SPORT & CLASSIC" => "GS&")
}

function removeAllAccents(s) {
  //TODO : æ --> ae, œ --> oe, ø, ð, þ ?
  //TODO : Æ --> AE, Œ --> OE, Ø, Ð, ß, Þ ?
  if (!s?.normalize) return s
  return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

function getFilterFunction(filter, locale) {
  filter = removeAllAccents(filter).toUpperCase()

  function includes(field, parts, AND) {
    if (!field) return false

    if (field instanceof Date) field = formatDate(field, locale)
    // $$typeof ==> react component
    if ((typeof field === "object" || Array.isArray(field)) && !field.$$typeof) field = JSON.stringify(field) // obj/array => string

    const _field = removeAllAccents(field.toString().trim()).toUpperCase() // number, boolean => string
    if (AND) {
      for (const part of parts) if (!_field.includes(part)) return false
      return true
    } else {
      for (const part of parts) if (_field.includes(part)) return true
      return false
    }
  }

  let parts, AND
  // Quotes (")
  if (filter.includes(`"`)) {
    parts = filter
      .split(`"`)
      .map(part => part.trim())
      .filter(part => part)
    AND = true
  }
  // Comma (,)
  else if (filter.includes(",")) {
    parts = filter
      .split(",")
      .map(part => part.trim())
      .filter(part => part)
  }
  // No commas + No quotes
  else {
    parts = filter
      .split(" ")
      .map(part => part.trim())
      .filter(part => part)
    AND = true
  }
  return field => includes(field, parts, AND)
}

/**
 * @param {[T]} items
 * @param {function?} conditionFunction
 * @param {[*]?} result
 * @returns Returns an array of items selected by calling conditionFunction on each of them.
 */
function getLeafItems(items = [], conditionFunction, result = []) {
  for (const item of items) {
    if (Array.isArray(item.items)) result = getLeafItems(item.items, conditionFunction, result)
    else if ((conditionFunction && conditionFunction(item)) || !conditionFunction) {
      result.push(item)
    }
  }
  return result
}

/**
 * @param {number} bytes
 * @returns Returns a human readable string representation of the bytes. For instance providing 1024 would return "1 MB". The unit choosen depends on the size of the bytes. Can be GB, KB or MB.
 */
function formatBytes(bytes) {
  if (isNaN(bytes)) return bytes

  let unit = "GB"
  let computedBytes = bytes / 1024 / 1024 / 1024
  if (computedBytes < 0.001) {
    unit = "KB"
    computedBytes *= 1024 * 1024
  } else if (computedBytes < 1) {
    unit = "MB"
    computedBytes *= 1024
  }

  return `${(Math.ceil(computedBytes * 200) / 200).toFixed(2)} ${unit}`
}

/**
 * "123456789" => "123 456 789"
 * @param {string} vat
 * @returns
 */
function formatSiren(siren) {
  if (!siren) return
  return siren.match(/.{1,3}/g).join(" ")
}

/**
 * "FR12333444555" => "FR 12 333 444 555"
 * @param {string} vat
 * @returns
 */
function formatVAT(vat) {
  if (!vat) return
  vat = vat.replace(/ /g, "") // Remove " "

  let formattedVat = ""
  for (let i = 0; i < vat.length; i++) {
    if ([2, 4, 7, 10].includes(i)) formattedVat += " "
    formattedVat += vat[i]
  }
  return formattedVat
}

/**
 * "+33555777017" => "05 55 77 70 17"
 * @param {string} vat
 * @returns
 */
function formatPhone(phone) {
  if (!phone) return
  phone = phone.replace(/\+33/g, "").replace(/ /g, "") //TODO : Only manages French phone numbers

  if (!phone.startsWith("0")) phone = `0${phone}` //TODO : Don't know how to manage special numbers

  let formattedPhone = ""
  for (let i = 0; i < phone.length; i++) {
    if (i % 2 === 0 && i !== 0) formattedPhone += " "
    formattedPhone += phone[i]
  }
  return formattedPhone
}

/**
 * @param {object} address
 * @param {string} address.address1
 * @param {string} address.address2
 * @param {string} address.zipcode
 * @param {string} address.city
 * @param {string} address.addressLine
 * @param {object} address.location
 * @param {[number]} address.location.coordinates
 * @returns Returns a string concatenating the address parts in this order : address1, address2, zipcode, city. If the concatenation is empty, attempts to return the 2 coordinates as a string with a comma separator. Otherwise return the addressLine as it is.
 */
function formatAddress(address) {
  if (!address) return address
  let ret = [address.address1, address.address2, address.zipcode, address.city].filter(it => it).join(" ")
  if (!ret && address.location?.coordinates) {
    // precision 1 meter
    const x = Math.round(address.location.coordinates[0] * 100000) / 100000
    const y = Math.round(address.location.coordinates[1] * 100000) / 100000
    if (x && y) ret = x.toString() + "," + y.toString()
  }
  return ret || address.addressLine
}

function arrayIntersects(obj1, obj2) {
  if (Array.isArray(obj1)) {
    if (Array.isArray(obj2)) {
      for (const it1 of obj1) {
        for (const it2 of obj2) {
          if (it1 === it2) return true
        }
      }
    } else if (obj2) {
      for (const it1 of obj1) {
        if (it1 === obj2) return true
      }
    }
  } else if (obj1) {
    if (Array.isArray(obj2)) {
      for (const it2 of obj2) {
        if (it2 === obj1) return true
      }
    } else if (obj2) {
      if (obj1 === obj2) return true
    }
  }
  return false
}

/**
 * Returns whether 2 variables are equal or not.
 * This function is much faster than JSON.stringify for comparing the equality of 2 objects.
 * Credits to https://github.com/epoberezkin/fast-deep-equal v3.1.3
 * On npm this package is much more downloaded per week than lodash isEqual (43M vs 10M).
 * This site https://www.measurethat.net/Benchmarks/Show/11503/1/lodashisequal-vs-fast-deep-equal#latest_results_block
 * shows on Chromium that lodash isEqual is a bit faster than the other package
 * but on Firefox lodash isEqual is much slower by several orders of magnitude.
 */
function isEqual(a, b) {
  if (a === b) return true

  if (a && b && typeof a == "object" && typeof b == "object") {
    if (a.constructor !== b.constructor) return false

    let length
    let i
    let keys
    if (Array.isArray(a)) {
      length = a.length
      if (length != b.length) return false
      for (i = length; i-- !== 0; ) if (!isEqual(a[i], b[i])) return false
      return true
    }

    if (a instanceof Map && b instanceof Map) {
      if (a.size !== b.size) return false
      for (i of a.entries()) if (!b.has(i[0])) return false
      for (i of a.entries()) if (!isEqual(i[1], b.get(i[0]))) return false
      return true
    }

    if (a instanceof Set && b instanceof Set) {
      if (a.size !== b.size) return false
      for (i of a.entries()) if (!b.has(i[0])) return false
      return true
    }

    if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
      length = a.length
      if (length != b.length) return false
      for (i = length; i-- !== 0; ) if (a[i] !== b[i]) return false
      return true
    }

    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf()
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString()

    keys = Object.keys(a)
    length = keys.length
    if (length !== Object.keys(b).length) return false

    for (i = length; i-- !== 0; ) if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false

    for (i = length; i-- !== 0; ) {
      const key = keys[i]
      if (!isEqual(a[key], b[key])) return false
    }

    //TODO : In javascript, the order of properties is truly stored, so it should be false :
    //a = { x : 45, y: "toto" }, b = { y: "toto", x : 45 } => Return true instead of false!
    return true
  }

  // true if both NaN, false otherwise
  return a !== a && b !== b
}

const coreCatalogNames = {
  FINANCING: "financing",
  BANKING: "banking",
}

/**
 * Mutates the supplied array by randomizing the order of its elements based on the Fisher–Yates algorithm.
 * The array is still returned to allow in-place notation.
 */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

/**
 * UUID format: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
 */
function isValidUuid(uuid) {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  return uuidRegex.test(uuid)
}

/**
 * Valid usernames are numbers, or strings that have either a valid email format or use only allowed characters.
 * If the tenant is provided, usernames must end with its name.
 * Warning: check order matters here.
 */
function isValidUsername({ username, tenant }) {
  if (!username || typeof username !== "string") return

  if (validationRegExps.emailRegExp.test(username)) return true
  if (!isNaN(username)) return true

  if (tenant && !username.endsWith(`@${tenant}`)) return
  // must contain an @ and it must not be at the end
  if (username.indexOf("@") === -1 || username.endsWith("@")) return
  // must not contain forbidden characters
  if (username.match(/[^a-zA-Z0-9.\-_@À-ÖØ-öø-ÿ]+/)) return
  return true
}

/**
 * Very basic reproduction of the MongoDB query engine used to evaluate queries against documents without having to insert them in the database.
 * For now only supports queries using type and status field in either string or array of primitives, and matching at the document root level.
 * Warning: this function must absolutely be a subset of what is capable the real MongoDB query engine,
 * because it is used to validate queries before executing them against MongoDB.
 * @returns True if query matches the document, a falsy value otherwise.
 */
function evalDbQueryMatch(document, query) {
  if (!document || typeof document !== "object" || !query || typeof query !== "object") return
  let isMatch = true
  for (const key in query) {
    if (!["type", "status"].includes(key)) continue
    const docValue = document[key]
    const queryValue = query[key]
    if (queryValue === docValue) continue
    if (Array.isArray(queryValue) && queryValue.includes(docValue)) continue
    if (Array.isArray(queryValue?.$in) && queryValue.$in.includes(docValue)) continue
    isMatch = false
  }
  return isMatch
}

function parseBoolean(bool) {
  if (bool === true || bool === "true") return true
  if (parseInt(bool) === 1) return true
  return false
}

function parseFrequencyValue(frequency, { usePlural } = {}) {
  const isDaily = frequency === "1"
  const isWeekly = frequency === "7"
  const isMonthly = frequency === "30"
  const isQuarter = frequency === "90"
  const isSemester = frequency === "180"
  const isAnnual = ["365", "360"].includes(frequency)
  return {
    isDaily,
    isWeekly,
    isMonthly,
    isQuarter,
    isSemester,
    isAnnual,
    label: isDaily
      ? usePlural
        ? "days"
        : "day"
      : isWeekly
      ? usePlural
        ? "weeks"
        : "week"
      : isMonthly
      ? usePlural
        ? "months"
        : "month"
      : isQuarter
      ? usePlural
        ? "quarters"
        : "quarter"
      : isSemester
      ? usePlural
        ? "semesters"
        : "semester"
      : isAnnual
      ? usePlural
        ? "years"
        : "year"
      : "",
  }
}

export {
  arrayIntersects,
  chunk,
  computePersonName,
  coreCatalogNames,
  evalDbQueryMatch,
  formatAddress,
  formatBytes,
  formatPhone,
  formatSiren,
  formatVAT,
  getFilterFunction,
  getLeafItems,
  isEqual,
  isValidUsername,
  isValidUuid,
  parseBoolean,
  parseFrequencyValue,
  removeAllAccents,
  shuffleArray,
  sleep,
  toDashed,
  toPlural,
  toSingular,
  toTrigram,
  validationRegExps,
}
