import axios from "axios"
import { objectToSearchParam } from "basikon-common-utils"

import consoleService from "@/_services/console"
import { getLabel, getRichValues } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { getOrganizationsObj, isTenantUseStandard } from "@/_services/utils"

export function getPersonName(person) {
  if (!person) return

  const { name, firstName = "", lastName = "" } = person
  if (name) return name

  let personName = ""
  if (firstName) personName += firstName
  if (lastName) {
    if (personName) personName += " "
    personName += lastName
  }

  return personName
}

export function getPersonDisplay(person) {
  if (!person) return
  let personName = person.name
  if (!personName) personName = getOrganizationsObj()[person.registration]?.name
  if (personName) return `${personName} (${person.registration})`
  return person.registration
}

export async function getQuickAddRelations(sourcePerson) {
  const scriptName = "quick-add-relations"

  let quickAddRelations
  try {
    quickAddRelations = (await axios.post(`/api/script/runs/${scriptName}`, { sourcePerson })).data
  } catch (error) {
    if (isTenantUseStandard()) {
      throw error
    } else {
      quickAddRelations = []
    }
  }

  // some checks
  for (const qar of quickAddRelations) {
    if (typeof qar.quickAddRoles === "string") {
      qar.quickAddRoles = [{ role: qar.quickAddRoles }]
    } else if (Array.isArray(qar.quickAddRoles) && typeof qar.quickAddRoles[0] === "string") {
      qar.quickAddRoles = qar.quickAddRoles.map(it => ({ role: it }))
    }
  }
  if (consoleService.showPersonsQuickAddRelations) console.log("QAR-0", [...quickAddRelations])
  return quickAddRelations
}

export async function getClientSignerRegistration(clientRegistration, isProspect) {
  let ascendants
  try {
    ascendants =
      (
        await axios.get(
          `/api/script/runs/person-ascendants${objectToSearchParam({
            personRegistration: clientRegistration,
            isProspect,
          })}`,
        )
      ).data || []
  } catch (error) {
    console.error(`Error getting client "${clientRegistration}" signer`, error)
    return
  }

  const signers = ascendants.filter(a => a.relation === "SIGNEROF")
  if (signers.length === 0) return

  const lastSigner = signers[signers.length - 1]
  if (!lastSigner) return

  return lastSigner.person?.registration
}

export async function getPerson(registration, query, isProspect) {
  if (!registration) return

  try {
    const person = (await axios.get(`/api/person/${isProspect ? "prospects" : "persons"}/${registration}` + objectToSearchParam(query || {}))).data
    return person
  } catch (error) {
    addOops(error)
  }
}

export async function getAndCachePerson({ registration, isProspect }) {
  const organizationsObj = getOrganizationsObj()
  if (!organizationsObj) return undefined
  if (!organizationsObj[registration]) {
    let person
    if (isProspect) {
      person = (await axios.get("/api/person/prospects/" + registration + "?projection=registration,name")).data
    } else {
      person = (await axios.get("/api/person/persons/" + registration + "?projection=registration,name")).data
    }
    organizationsObj[registration] = person
  }
  return organizationsObj[registration]
}

export async function getAndCachePersonName(registration) {
  return (await getAndCachePerson({ registration })).name
}

// build a list of all roles for scheme payer / payee definition
export function getSchemesRolesValues() {
  // const organizationRoleValues = getValues("organizationRole") || []
  const personRoleValues = getRichValues("personRole") || []
  const schemeRoleValues = [
    { value: "TENANT", label: loc`Tenant` + " (TENANT)" },
    { value: "ORGAREGISTRATION", label: loc`Organization` + " (ORGAREGISTRATION)" },
    { value: "FUNDORGAREGISTRATION", label: loc`Fund organization` + " (FUNDORGAREGISTRATION)" },
    // { value: "ORGANIZATION", label: loc`Organization` + " (ORGANIZATION)" },
    // ...organizationRoleValues?.map(it => ({ value: "ORG:" + it.value, label: loc`${it.label}: (ORG:${it.value})` })),
    { value: "PERSON", label: loc`Person` + " (PERSON)" },
    ...personRoleValues,
  ].filter(it => it)
  return schemeRoleValues
}

export function getPayerPayeeValues(orgaRegistration, entityPersons, options) {
  const { withOrganizations, richValuesList } = options || {}

  let values = []
  if (withOrganizations) {
    const organizationsObj = getOrganizationsObj()
    values = Object.keys(organizationsObj || {}).map(key => {
      let label = organizationsObj[key].name
      if (richValuesList) {
        // look for the first role of the entity which is not ORGANIZATION, assume it can be used as a key
        // this is not satisfying, we could imagine that the actual org role is not the first in list, meaning the entity will not be found
        const role = organizationsObj[key].roles?.find(it => it.role !== "ORGANIZATION")?.[0]?.role

        if (role) label += " [" + getLabel("personRole", role) + "]"
        label += " (" + key + ")"
      }
      return { value: key, label }
    })
  }

  if (orgaRegistration) {
    // this orgaRegistration should be in getOrganizationsObj()
    // if not present that means the /api/persons/organizations-with-ascendants has not returned it
    // and it implies this user is not a LOCALBO and should not see this contract and it is not normal so we add (???)
    const organizationsObj = getOrganizationsObj()
    const orgaPerson = organizationsObj[orgaRegistration]
    const label = orgaPerson ? getPersonName(orgaPerson) : orgaRegistration + " (???)"
    values.push({ value: "ORGANIZATION", label })
  }

  for (let it of entityPersons || []) {
    const exists = values.find(({ value }) => value === it.personRegistration)
    if (!exists) {
      let label = getPersonName(it.person)
      if (richValuesList) {
        const { role } = it
        if (role) label += " [" + getLabel("personRole", role) + "]"
        label += " (" + it.personRegistration + ")"
      }
      values.push({ value: it.personRegistration, label })
    }
  }
  return values
}

// The below code is now available in basikon-server-external / pivot registration / ESP.
// It should be moved out.
export function validateSpanishId(str) {
  const DNI_REGEX = /^(\d{8})([A-Z])$/
  const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
  const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/

  const validationFunctions = {
    DNI: function (dni) {
      const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE"
      const letter = dni_letters.charAt(parseInt(dni, 10) % 23)

      return letter === dni.charAt(8)
    },
    NIE: function (nie) {
      // Change the initial letter for the corresponding number and validate as DNI
      let nie_prefix = nie.charAt(0)

      switch (nie_prefix) {
        case "X":
          nie_prefix = 0
          break
        case "Y":
          nie_prefix = 1
          break
        case "Z":
          nie_prefix = 2
          break
      }

      return validationFunctions.DNI(nie_prefix + nie.substr(1))
    },
    CIF: function (cif) {
      const match = cif.match(CIF_REGEX)
      const letter = match[1]
      const number = match[2]
      const control = match[3]

      let evenSum = 0
      let oddSum = 0
      let n

      for (let i = 0; i < number.length; i++) {
        n = parseInt(number[i], 10)

        // Odd positions (Even index equals to odd position. i=0 equals first position)
        if (i % 2 === 0) {
          // Odd positions are multiplied first.
          n *= 2

          // If the multiplication is bigger than 10 we need to adjust
          oddSum += n < 10 ? n : n - 9

          // Even positions
          // Just sum them
        } else {
          evenSum += n
        }
      }

      const controlDigit = (10 - (evenSum + oddSum).toString().slice(-1)).toString()
      const controlLetter = "JABCDEFGHI".substring(controlDigit, 1)

      // Control must be a digit
      if (letter.match(/[ABEH]/)) return control === controlDigit

      // Control must be a letter
      if (letter.match(/[KPQS]/)) return control === controlLetter

      // Can be either
      return control === controlDigit || control === controlLetter
    },
  }

  str = str.toUpperCase().replace(/\s/, "")

  let type
  if (str.match(DNI_REGEX)) type = "DNI"
  if (str.match(CIF_REGEX)) type = "CIF"
  if (str.match(NIE_REGEX)) type = "NIE"

  return {
    type,
    isValid: validationFunctions[type] ? validationFunctions[type](str) : false,
  }
}

export const personTypes = {
  COMPANY: "C",
  EMPLOYEE: "E",
  INDIVIDUAL: "I",
  ORGANIZATION: "O",
  PROFESSIONAL: "P",
}

export function getPersonDefaultRows(person, detailed) {
  const showFamilyEmploymentIncomeExpenditure =
    person.type !== personTypes.COMPANY && (!person.props_familyEmploymentIncomeExpenditure || !person.props_familyEmploymentIncomeExpenditure.hidden) // tmp until moved to extension
  const showPersonFamily = !person.props_family || !person.props_family.hidden
  const showPersonEmployment = !person.props_employment || !person.props_employment.hidden
  const showPersonIncome = !person.props_income || !person.props_income.hidden
  const showPersonExpenditure = !person.props_expenditure || !person.props_expenditure.hidden
  const showPersonWeb = !person.props_web || !person.props_web.hidden
  const showLegal = !person.props_legal || !person.props_legal.hidden

  const isCompanyOrga = [personTypes.COMPANY, personTypes.ORGANIZATION].includes(person.type)
  const isPro = person.type === personTypes.PROFESSIONAL

  return [
    [
      "registration",
      isCompanyOrga ? "name" : "title",
      isCompanyOrga ? "commercialName" : "firstName",
      isCompanyOrga ? "legalForm" : "lastName",
      isCompanyOrga ? "preferredLanguage" : "secondName",
      "phone",
      "mobile",
      "email",
      ...(isPro ? ["legalForm"] : []),
      isCompanyOrga ? "currency" : "preferredLanguage",
      "addressLine",
    ],
    isCompanyOrga || isPro
      ? showPersonWeb && {
          title: loc`Web`,
          collapse: false,
          className: detailed ? "" : "d-none",
          rows: [["website", "linkedIn", "facebook", "instagram"]],
        }
      : [{ className: detailed ? "" : "d-none", rows: [["birthDate", "birthPlace", "birthZipcode", "birthCountry", "nationality"]] }],
    "contacts",
    "address",
    "addresses",
    "bankAccounts",
    ...(showFamilyEmploymentIncomeExpenditure && showPersonFamily
      ? [
          {
            title: loc`Family`,
            collapse: false,
            className: detailed ? "" : "d-none",
            rows: [["familyMaritalStatus", "familyMaritalContract", "familyNumberOfDependents"]],
          },
        ]
      : []),
    ...(showFamilyEmploymentIncomeExpenditure && showPersonEmployment
      ? [
          {
            title: loc`Employment`,
            collapse: false,
            className: detailed ? "" : "d-none",
            rows: [["employmentJobDescription", "employmentSector", "employmentContractType", "employmentStartDate", "employmentEndDate"]],
          },
        ]
      : []),
    ...(showFamilyEmploymentIncomeExpenditure && showPersonIncome
      ? [
          {
            title: loc`Income (monthly net)`,
            collapse: false,
            className: detailed ? "" : "d-none",
            rows: [["incomeSource", "incomeSalary", "incomeHousing", "incomeAllocation", "incomeOther", "incomeTotal"]],
          },
        ]
      : []),
    ...(showFamilyEmploymentIncomeExpenditure && showPersonExpenditure
      ? [
          {
            title: loc`Expenditure (monthly net)`,
            collapse: false,
            className: detailed ? "" : "d-none",
            rows: [
              [
                "expenditureHousingMonthlyRental",
                "expenditureHousingMortgageRepayment",
                "expenditureOtherLoanRepayment",
                "expenditureAlimony",
                "expenditureTaxes",
                "expenditureTotal",
              ],
            ],
          },
        ]
      : []),
    ...((isCompanyOrga || person.type === personTypes.PROFESSIONAL) && showLegal
      ? [
          {
            title: loc`Legal`,
            collapse: false,
            className: detailed ? "" : "d-none",
            rows: [
              [
                "legalForm",
                "creationDate",
                "registrationDate",
                "shareCapital",
                "registrationCountry",
                "registrationPlace",
                "registrationInfo",
                "vatScheme",
                "activityCode",
                "naceCode",
                "sectorCode",
                "insuranceNumber",
                "legalRepresentative",
                "administrativeStatus",
              ],
              [
                "yearOfReference",
                "companySize",
                "nbOfEmployees",
                "accountingStandards",
                "totalBalancesheet",
                "totalTurnover",
                "legalProceedingsStatus",
                "dateLegalProceeding",
              ],
            ],
          },
        ]
      : []),
  ]
}
