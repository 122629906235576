import React from "react"

const AuthenticationLayout = React.lazy(() => import("@/authentication/AuthenticationLayout"))
const DtxnsPage = React.lazy(() => import("@/_pages/DtxnsPage"))
const EntitiesPage = React.lazy(() => import("@/_pages/EntitiesPage"))
const EntityPage = React.lazy(() => import("@/_pages/EntityPage"))
const ScriptsPage = React.lazy(() => import("@/script/ScriptsPage"))
const ChatPage = React.lazy(() => import("@/_pages/ChatPage"))
const ImportsPage = React.lazy(() => import("@/_pages/ImportsPage"))
const MappingPage = React.lazy(() => import("@/_pages/MappingPage"))
const ExportsPage = React.lazy(() => import("@/_pages/ExportsPage"))
const GlobalSearchPage = React.lazy(() => import("@/_pages/GlobalSearchPage"))
const DocumentationSearchPage = React.lazy(() => import("@/_pages/DocumentationSearchPage"))
const ApiDocumentationPage = React.lazy(() => import("@/_pages/ApiDocumentationPage"))
const DocumentationPage = React.lazy(() => import("@/_pages/DocumentationPage"))
const AuditsPage = React.lazy(() => import("@/_pages/AuditsPage"))
const TasksPage = React.lazy(() => import("@/_pages/TasksPage" /* webpackChunkName: "precache" */))
const TaskPage = React.lazy(() => import("@/_pages/TaskPage"))
const LocalesPage = React.lazy(() => import("@/_pages/LocalesPage"))
const LocalePage = React.lazy(() => import("@/_pages/LocalePage"))
const CalendarPage = React.lazy(() => import("@/_pages/CalendarPage"))
const MetricsPage = React.lazy(() => import("@/_pages/MetricsPage"))
const MetricRecordsPage = React.lazy(() => import("@/_pages/MetricRecordsPage"))
const TenantPage = React.lazy(() => import("@/_pages/TenantPage"))
const TenantsPage = React.lazy(() => import("@/_pages/TenantsPage"))
const EditCatalogPage = React.lazy(() => import("@/_pages/EditCatalogPage"))
const CatalogPage = React.lazy(() => import("@/_pages/CatalogPage" /* webpackChunkName: "precache" */))
const BatchPage = React.lazy(() => import("@/_pages/BatchPage"))
const BatchesPage = React.lazy(() => import("@/_pages/BatchesPage"))
const RatesPage = React.lazy(() => import("@/_pages/RatesPage"))
const TestsPage = React.lazy(() => import("@/_pages/TestsPage"))
const ObjectStorageContainers = React.lazy(() => import("@/_pages/ObjectStorageContainers"))
const ObjectStorageContainer = React.lazy(() => import("@/_pages/ObjectStorageContainer"))
const ThemesPage = React.lazy(() => import("@/_pages/ThemesPage"))
const IconsPage = React.lazy(() => import("@/_pages/IconsPage"))
const FeatureFlagPage = React.lazy(() => import("@/_pages/FeatureFlagPage"))
const NotificationsPage = React.lazy(() => import("@/_pages/NotificationsPage"))
const KeyValuesPage = React.lazy(() => import("@/_pages/KeyValuesPage"))
const CacheInfo = React.lazy(() => import("@/_pages/CacheInfo"))
const Page = React.lazy(() => import("@/_pages/Page"))

const UserPage = React.lazy(() => import("@/user/UserPage" /* webpackChunkName: "precache" */))
const UsersPage = React.lazy(() => import("@/user/UsersPage"))

const AccessLogsPage = React.lazy(() => import("@/system/AccessLogsPage"))
const RequestLogPage = React.lazy(() => import("@/system/RequestLogPage"))
const SystemLogsPage = React.lazy(() => import("@/system/SystemLogsPage"))
const SystemEventsPage = React.lazy(() => import("@/system/SystemEventsPage"))

const ProspectsOrPersonsPage = React.lazy(() => import("@/person/ProspectsOrPersonsPage"))
const ProspectOrPersonPage = React.lazy(() => import("@/person/ProspectOrPersonPage"))

const FundsPage = React.lazy(() => import("@/fund/FundsPage"))
const FundPage = React.lazy(() => import("@/fund/FundPage"))
const FundingsPage = React.lazy(() => import("@/fund/FundingsPage"))
const FundingPage = React.lazy(() => import("@/fund/FundingPage"))

const AssetPage = React.lazy(() => import("@/asset/AssetPage"))
const AssetLotPage = React.lazy(() => import("@/asset/AssetLotPage"))
const CollateralPage = React.lazy(() => import("@/asset/CollateralPage"))
const PrivateEquityAssetPage = React.lazy(() => import("@/privateEquity/PrivateEquityAssetPage"))
const PrivateEquityOperationPage = React.lazy(() => import("@/privateEquity/PrivateEquityOperationPage"))

const DealOrContractPage = React.lazy(() => import("@/financing/DealOrContractPage" /* webpackChunkName: "precache" */))
const ContractElementPage = React.lazy(() => import("@/financing/ContractElementPage"))
const ContractLotPage = React.lazy(() => import("@/financing/ContractLotPage"))
const DealsOrContractsPage = React.lazy(() => import("@/financing/DealsOrContractsPage" /* webpackChunkName: "precache" */))
const MaintenancePage = React.lazy(() => import("@/financing/MaintenancePage"))
const CreditLinesPage = React.lazy(() => import("@/financing/CreditLinesPage"))
const CreditLinePage = React.lazy(() => import("@/financing/CreditLinePage"))
const AgreementPage = React.lazy(() => import("@/financing/AgreementPage"))
const AgreementsPage = React.lazy(() => import("@/financing/AgreementsPage"))
const AmendmentsPage = React.lazy(() => import("@/financing/AmendmentsPage"))
const AmendmentPage = React.lazy(() => import("@/financing/AmendmentPage"))
const FinancingServicePage = React.lazy(() => import("@/financing/FinancingServicePage"))

const ExternalDatasPage = React.lazy(() => import("@/external/ExternalDatasPage"))
const ExternalDataPage = React.lazy(() => import("@/external/ExternalDataPage"))

const CampaignPage = React.lazy(() => import("@/crm/CampaignPage"))

const CasePage = React.lazy(() => import("@/case/CasePage"))
const RulePage = React.lazy(() => import("@/case/RulePage"))

const InvoicePage = React.lazy(() => import("@/billing/InvoicePage"))
const CashflowPage = React.lazy(() => import("@/billing/CashflowPage"))
const CashflowsPage = React.lazy(() => import("@/billing/CashflowsPage"))
const InvoicesPage = React.lazy(() => import("@/billing/InvoicesPage"))
const SepaPage = React.lazy(() => import("@/billing/SepaPage"))
const SepasPage = React.lazy(() => import("@/billing/SepasPage"))
const CamtPage = React.lazy(() => import("@/billing/CamtPage"))
const CamtsPage = React.lazy(() => import("@/billing/CamtsPage"))
const PaymentFilePage = React.lazy(() => import("@/billing/PaymentFilePage"))
const PaymentFileLinePage = React.lazy(() => import("@/billing/PaymentFileLinePage"))

const BankAccountsPage = React.lazy(() => import("@/bank/BankAccountsPage"))
const BankOperationsPage = React.lazy(() => import("@/bank/BankOperationsPage"))
const BankOperationPage = React.lazy(() => import("@/bank/BankOperationPage"))
const BankTransactionsPage = React.lazy(() => import("@/bank/BankTransactionsPage"))
const BankTransactionPage = React.lazy(() => import("@/bank/BankTransactionPage"))
const BankAccountPage = React.lazy(() => import("@/bank/BankAccountPage"))
const MoveMoneyPage = React.lazy(() => import("@/bank/MoveMoneyPage"))
const ArbitrationPage = React.lazy(() => import("@/bank/ArbitrationPage"))
const SetOverdraftPage = React.lazy(() => import("@/bank/SetOverdraftPage"))

const InventoryPage = React.lazy(() => import("@/accounting/InventoryPage"))
const InventoriesPage = React.lazy(() => import("@/accounting/InventoriesPage"))
const PostingOrPostingEventPage = React.lazy(() => import("@/accounting/PostingOrPostingEventPage"))
const AccountingCatalogItemPage = React.lazy(() => import("@/accounting/AccountingCatalogItemPage"))
const AccountingCatalogItemsPage = React.lazy(() => import("@/accounting/AccountingCatalogItemsPage"))

const ScorePage = React.lazy(() => import("@/score/ScorePage"))

const ProductsPage = React.lazy(() => import("@/product/ProductsPage"))
const ProductPage = React.lazy(() => import("@/product/ProductPage"))
const ProductTablesPage = React.lazy(() => import("@/product/ProductTablesPage"))
const ProductTablePage = React.lazy(() => import("@/product/ProductTablePage"))
const ProductAmendmentPage = React.lazy(() => import("@/product/ProductAmendmentPage"))
const ProductAmendmentsPage = React.lazy(() => import("@/product/ProductAmendmentsPage"))
const ProductElementCatalogItemPage = React.lazy(() => import("@/product/ProductElementCatalogItemPage"))
const ProductElementCatalogItemsPage = React.lazy(() => import("@/product/ProductElementCatalogItemsPage"))

const ProjectPage = React.lazy(() => import("@/support/ProjectPage"))
const BudgetPage = React.lazy(() => import("@/support/BudgetPage"))
const BudgetsPage = React.lazy(() => import("@/support/BudgetsPage"))
const CommitteePage = React.lazy(() => import("@/support/CommitteePage"))
const CommitteesPage = React.lazy(() => import("@/support/CommitteesPage"))
const TicketPage = React.lazy(() => import("@/support/TicketPage"))
const QuestionPage = React.lazy(() => import("@/support/QuestionPage"))
const TicketsPage = React.lazy(() => import("@/support/TicketsPage"))
const TimeEntriesPage = React.lazy(() => import("@/support/TimeEntriesPage"))
const VotePage = React.lazy(() => import("@/support/VotePage"))
const VotesPage = React.lazy(() => import("@/support/VotesPage"))
const VotingPage = React.lazy(() => import("@/support/VotingPage"))
const VotingsPage = React.lazy(() => import("@/support/VotingsPage"))
const ClaimPage = React.lazy(() => import("@/case/ClaimPage"))

const CommunicationPage = React.lazy(() => import("@/communication/CommunicationPage"))

const rootRoutes = [{ path: "/authentication", render: routeProps => <AuthenticationLayout {...routeProps} /> }]

const pagesLayoutRoutes = [
  { path: "/content/*", render: routeProps => <Page {...routeProps} /> },
  { path: "/user-content/*", render: routeProps => <Page {...routeProps} /> },
  { path: "/user-content", render: routeProps => <Page {...routeProps} /> },
  { path: "/page/*", render: routeProps => <Page {...routeProps} /> },
  { path: "/catalog/:name", render: routeProps => <CatalogPage {...routeProps} /> },
  { path: "/edit-catalog/:name", render: routeProps => <EditCatalogPage {...routeProps} /> },
  { path: "/asset-catalog-items", render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/asset-catalog-item", exact: true, render: routeProps => <EntityPage {...routeProps} /> },
  { path: "/asset-catalog-item/:id", render: routeProps => <EntityPage {...routeProps} /> },
  { path: "/users", exact: true, render: routeProps => <UsersPage {...routeProps} /> },
  { path: "/user", exact: true, render: routeProps => <UserPage {...routeProps} /> },
  { path: "/user/:id", render: routeProps => <UserPage {...routeProps} /> },
  { path: "/batches", exact: true, render: routeProps => <BatchesPage {...routeProps} /> },
  { path: "/batch", exact: true, render: routeProps => <BatchPage {...routeProps} /> },
  { path: "/batch/:id", render: routeProps => <BatchPage {...routeProps} /> },
  { path: "/scripts", render: routeProps => <ScriptsPage {...routeProps} /> },
  { path: "/cache-info", render: routeProps => <CacheInfo {...routeProps} /> },
  {
    path: "/script-logs",
    render: routeProps => (
      <EntitiesPage
        {...routeProps}
        pageConfig={{ addButton: { hidden: true }, hideAutocomplete: true, hideKpis: true, pageSize: 20, transitionsButton: { hidden: true } }}
      />
    ),
  },
  { path: "/chat", render: routeProps => <ChatPage {...routeProps} /> },
  { path: "/imports", render: routeProps => <ImportsPage {...routeProps} /> },

  { path: "/mapping", exact: true, render: routeProps => <MappingPage {...routeProps} /> },
  { path: "/mapping/:id", exact: true, render: routeProps => <MappingPage {...routeProps} /> },

  { path: "/documentation/api", render: routeProps => <ApiDocumentationPage {...routeProps} /> },
  { path: "/documentation", render: routeProps => <DocumentationPage {...routeProps} /> },
  { path: "/documentation-search", render: routeProps => <DocumentationSearchPage {...routeProps} /> },

  { path: "/global-search", render: routeProps => <GlobalSearchPage {...routeProps} /> },
  { path: "/audits", exact: true, render: routeProps => <AuditsPage {...routeProps} /> },

  { path: "/tasks", exact: true, render: routeProps => <TasksPage {...routeProps} /> },
  { path: "/task", exact: true, render: routeProps => <TaskPage {...routeProps} /> },
  { path: "/task/:id", exact: true, render: routeProps => <TaskPage {...routeProps} /> },
  { path: "/locales", exact: true, render: routeProps => <LocalesPage {...routeProps} /> },
  { path: "/locale/:id", exact: true, render: routeProps => <LocalePage {...routeProps} /> },

  { path: "/notes", render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/note", exact: true, render: routeProps => <EntityPage {...routeProps} hasWorkflow={false} /> },
  { path: "/note/:id", render: routeProps => <EntityPage {...routeProps} hasWorkflow={false} /> },

  { path: "/calendar", exact: true, render: routeProps => <CalendarPage {...routeProps} /> },
  { path: "/exports", exact: true, render: routeProps => <ExportsPage {...routeProps} /> },
  { path: "/metrics", exact: true, render: routeProps => <MetricsPage {...routeProps} /> },
  { path: "/metric-record/:key", exact: true, render: routeProps => <MetricRecordsPage {...routeProps} /> },
  { path: "/tenant", render: routeProps => <TenantPage {...routeProps} /> },
  { path: "/tenants", exact: true, render: routeProps => <TenantsPage {...routeProps} /> },
  { path: "/system-events", exact: true, render: routeProps => <SystemEventsPage {...routeProps} /> },
  { path: "/system-logs", exact: true, render: routeProps => <SystemLogsPage {...routeProps} /> },
  { path: "/access-logs", exact: true, render: routeProps => <AccessLogsPage {...routeProps} /> },
  { path: "/request-log/:requestId", exact: true, render: routeProps => <RequestLogPage {...routeProps} /> },
  { path: "/accounting-catalog-items", render: routeProps => <AccountingCatalogItemsPage {...routeProps} /> },
  { path: "/accounting-catalog-item", exact: true, render: routeProps => <AccountingCatalogItemPage {...routeProps} /> },
  { path: "/accounting-catalog-item/:id", render: routeProps => <AccountingCatalogItemPage {...routeProps} /> },
  { path: "/bookings", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/booking", exact: true, render: routeProps => <PostingOrPostingEventPage {...routeProps} /> },
  { path: "/booking/:id", exact: true, render: routeProps => <PostingOrPostingEventPage {...routeProps} /> },
  { path: "/inventories", exact: true, render: routeProps => <InventoriesPage {...routeProps} /> },
  { path: "/inventory", exact: true, render: routeProps => <InventoryPage {...routeProps} /> },
  { path: "/inventory/:id", exact: true, render: routeProps => <InventoryPage {...routeProps} /> },
  { path: "/postings", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/posting", exact: true, render: routeProps => <PostingOrPostingEventPage {...routeProps} /> },
  { path: "/posting/:id", exact: true, render: routeProps => <PostingOrPostingEventPage {...routeProps} /> },
  { path: "/posting-events", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/posting-event", exact: true, render: routeProps => <PostingOrPostingEventPage {...routeProps} /> },
  { path: "/posting-event/:id", exact: true, render: routeProps => <PostingOrPostingEventPage {...routeProps} /> },
  { path: "/bank-accounts", exact: true, render: routeProps => <BankAccountsPage {...routeProps} /> },
  { path: "/bank-account", exact: true, render: routeProps => <BankAccountPage {...routeProps} /> },
  { path: "/bank-account/:id", render: routeProps => <BankAccountPage {...routeProps} /> },
  { path: "/bank-operations", exact: true, render: routeProps => <BankOperationsPage {...routeProps} /> },
  { path: "/bank-operation", exact: true, render: routeProps => <BankOperationPage {...routeProps} /> },
  { path: "/bank-operation/:id", render: routeProps => <BankOperationPage {...routeProps} /> },
  { path: "/bank-transactions", exact: true, render: routeProps => <BankTransactionsPage {...routeProps} /> },
  { path: "/bank-transaction/:id", exact: true, render: routeProps => <BankTransactionPage {...routeProps} /> },
  { path: "/move-money", exact: true, render: routeProps => <MoveMoneyPage {...routeProps} /> },
  { path: "/arbitration", exact: true, render: routeProps => <ArbitrationPage {...routeProps} /> },
  { path: "/bank/set-overdraft", exact: true, render: routeProps => <SetOverdraftPage {...routeProps} /> },
  { path: "/invoices", exact: true, render: routeProps => <InvoicesPage {...routeProps} /> },
  { path: "/invoice", exact: true, render: routeProps => <InvoicePage {...routeProps} /> },
  { path: "/invoice/:id", render: routeProps => <InvoicePage {...routeProps} /> },
  { path: "/cashflows", exact: true, render: routeProps => <CashflowsPage {...routeProps} /> },
  { path: "/cashflow", exact: true, render: routeProps => <CashflowPage {...routeProps} /> },
  { path: "/cashflow/:id", render: routeProps => <CashflowPage {...routeProps} /> },
  { path: "/sepas", exact: true, render: routeProps => <SepasPage {...routeProps} /> },
  { path: "/sepa/:id", exact: true, render: routeProps => <SepaPage {...routeProps} /> },
  { path: "/camts", exact: true, render: routeProps => <CamtsPage {...routeProps} /> },
  { path: "/camt/:id", exact: true, render: routeProps => <CamtPage {...routeProps} /> },
  { path: "/rules", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/rule", exact: true, render: routeProps => <RulePage {...routeProps} /> },
  { path: "/rule/:id", exact: false, render: routeProps => <RulePage {...routeProps} /> },
  { path: "/cases", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/case", exact: true, render: routeProps => <CasePage {...routeProps} /> },
  { path: "/case/:id", exact: false, render: routeProps => <CasePage {...routeProps} /> },
  { path: "/campaigns", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/campaign", exact: true, render: routeProps => <CampaignPage {...routeProps} /> },
  { path: "/campaign/:id", render: routeProps => <CampaignPage {...routeProps} /> },
  { path: "/votings", exact: true, render: routeProps => <VotingsPage {...routeProps} /> },
  { path: "/voting", exact: true, render: routeProps => <VotingPage {...routeProps} /> },
  { path: "/voting/:id", render: routeProps => <VotingPage {...routeProps} /> },
  { path: "/votes", exact: true, render: routeProps => <VotesPage {...routeProps} /> },
  { path: "/vote", exact: true, render: routeProps => <VotePage {...routeProps} /> },
  { path: "/vote/:id", render: routeProps => <VotePage {...routeProps} /> },

  { path: "/committees", exact: true, render: routeProps => <CommitteesPage {...routeProps} /> },
  { path: "/committee", exact: true, render: routeProps => <CommitteePage {...routeProps} /> },
  { path: "/committee/:id", exact: false, render: routeProps => <CommitteePage {...routeProps} /> },

  { path: "/questions", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/question", exact: true, render: routeProps => <QuestionPage {...routeProps} /> },
  { path: "/question/:id", exact: false, render: routeProps => <QuestionPage {...routeProps} /> },

  { path: "/projects", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/project", exact: true, render: routeProps => <ProjectPage {...routeProps} /> },
  { path: "/project/:id", exact: false, render: routeProps => <ProjectPage {...routeProps} /> },

  { path: "/budgets", exact: true, render: routeProps => <BudgetsPage {...routeProps} /> },
  { path: "/budget", exact: true, render: routeProps => <BudgetPage {...routeProps} /> },
  { path: "/budget/:id", exact: false, render: routeProps => <BudgetPage {...routeProps} /> },

  { path: "/external-datas", exact: true, render: routeProps => <ExternalDatasPage {...routeProps} /> },
  { path: "/external-data", exact: true, render: routeProps => <ExternalDataPage {...routeProps} /> },
  { path: "/external-data/:id", exact: true, render: routeProps => <ExternalDataPage {...routeProps} /> },
  { path: "/credit-lines", exact: true, render: routeProps => <CreditLinesPage {...routeProps} /> },
  { path: "/credit-line", exact: true, render: routeProps => <CreditLinePage {...routeProps} /> },
  { path: "/credit-line/:id", render: routeProps => <CreditLinePage {...routeProps} /> },
  { path: "/contracts", exact: true, render: routeProps => <DealsOrContractsPage {...routeProps} /> },
  { path: "/contract", exact: true, render: routeProps => <DealOrContractPage {...routeProps} /> },
  { path: "/contract/:id", render: routeProps => <DealOrContractPage {...routeProps} /> },

  { path: "/deals", exact: true, render: routeProps => <DealsOrContractsPage {...routeProps} /> },
  { path: "/deal", exact: true, render: routeProps => <DealOrContractPage {...routeProps} /> },
  { path: "/deal/:id", render: routeProps => <DealOrContractPage {...routeProps} /> },

  { path: "/contract-elements", exact: true, render: routeProps => <EntitiesPage hideAddButton {...routeProps} /> },
  { path: "/contract-element/:id", render: routeProps => <ContractElementPage {...routeProps} /> },

  { path: "/contract-lots", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/contract-lot", exact: true, render: routeProps => <ContractLotPage {...routeProps} /> },
  { path: "/contract-lot/:id", render: routeProps => <ContractLotPage {...routeProps} /> },

  { path: "/asset-lots", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/asset-lot", exact: true, render: routeProps => <AssetLotPage {...routeProps} /> },
  { path: "/asset-lot/:id", render: routeProps => <AssetLotPage {...routeProps} /> },

  { path: "/maintenances", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/maintenance", exact: true, render: routeProps => <MaintenancePage {...routeProps} /> },
  { path: "/maintenance/:id", render: routeProps => <MaintenancePage {...routeProps} /> },

  { path: "/assets", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/asset", exact: true, render: routeProps => <AssetPage {...routeProps} /> },
  { path: "/asset/:id", render: routeProps => <AssetPage {...routeProps} /> },
  { path: "/agreements", exact: true, render: routeProps => <AgreementsPage {...routeProps} /> },
  { path: "/agreement", exact: true, render: routeProps => <AgreementPage {...routeProps} /> },
  { path: "/agreement/:id", render: routeProps => <AgreementPage {...routeProps} /> },
  { path: "/amendments", render: routeProps => <AmendmentsPage {...routeProps} /> },
  { path: "/amendment/:id", render: routeProps => <AmendmentPage {...routeProps} /> },
  { path: "/amendment", render: routeProps => <AmendmentPage {...routeProps} /> },
  { path: "/funds", exact: true, render: routeProps => <FundsPage {...routeProps} /> },
  { path: "/fund", exact: true, render: routeProps => <FundPage {...routeProps} /> },
  { path: "/fund/:id", exact: false, render: routeProps => <FundPage {...routeProps} /> },
  { path: "/fundings", exact: true, render: routeProps => <FundingsPage {...routeProps} /> },
  { path: "/funding/:id", exact: false, render: routeProps => <FundingPage {...routeProps} /> },

  { path: "/persons", exact: false, render: routeProps => <ProspectsOrPersonsPage {...routeProps} /> },
  { path: "/person", exact: true, render: routeProps => <ProspectOrPersonPage {...routeProps} /> },
  { path: "/person/:id", exact: false, render: routeProps => <ProspectOrPersonPage {...routeProps} /> },
  { path: "/person-amendments", render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/person-amendment", exact: true, render: routeProps => <EntityPage {...routeProps} /> },
  { path: "/person-amendment/:id", render: routeProps => <EntityPage {...routeProps} /> },
  { path: "/prospects", exact: false, render: routeProps => <ProspectsOrPersonsPage {...routeProps} /> },
  { path: "/prospect", exact: true, render: routeProps => <ProspectOrPersonPage {...routeProps} /> },
  { path: "/prospect/:id", exact: false, render: routeProps => <ProspectOrPersonPage {...routeProps} /> },

  { path: "/scores", exact: false, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/score", exact: true, render: routeProps => <ScorePage {...routeProps} /> },
  { path: "/score/:id", exact: false, render: routeProps => <ScorePage {...routeProps} /> },
  { path: "/products", exact: false, render: routeProps => <ProductsPage {...routeProps} /> },
  { path: "/product", exact: true, render: routeProps => <ProductPage {...routeProps} /> },
  { path: "/product/:id", exact: false, render: routeProps => <ProductPage {...routeProps} /> },
  { path: "/product-catalog-items", render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/product-catalog-item", exact: true, render: routeProps => <EntityPage {...routeProps} /> },
  { path: "/product-catalog-item/:id", render: routeProps => <EntityPage {...routeProps} /> },
  { path: "/documents", render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/product-element-catalog-items", render: routeProps => <ProductElementCatalogItemsPage {...routeProps} /> },
  { path: "/product-element-catalog-item", exact: true, render: routeProps => <ProductElementCatalogItemPage {...routeProps} /> },
  { path: "/product-element-catalog-item/:id", render: routeProps => <ProductElementCatalogItemPage {...routeProps} /> },
  { path: "/product-tables", exact: false, render: routeProps => <ProductTablesPage {...routeProps} /> },
  { path: "/product-table", exact: true, render: routeProps => <ProductTablePage {...routeProps} /> },
  { path: "/product-table/:id", exact: false, render: routeProps => <ProductTablePage {...routeProps} /> },
  { path: "/product-amendments", exact: false, render: routeProps => <ProductAmendmentsPage {...routeProps} /> },
  { path: "/product-amendment/:id", exact: true, render: routeProps => <ProductAmendmentPage {...routeProps} /> },
  { path: "/product-amendment", exact: true, render: routeProps => <ProductAmendmentPage {...routeProps} /> },
  { path: "/tickets", exact: false, render: routeProps => <TicketsPage {...routeProps} /> },
  { path: "/ticket/:id", exact: true, render: routeProps => <TicketPage {...routeProps} /> },
  { path: "/ticket", exact: true, render: routeProps => <TicketPage {...routeProps} /> },
  { path: "/time-entries", exact: false, render: routeProps => <TimeEntriesPage {...routeProps} /> },
  { path: "/rates", exact: false, render: routeProps => <RatesPage {...routeProps} /> },
  {
    path: "/communications",
    exact: true,
    render: routeProps => <EntitiesPage {...routeProps} pageConfig={{ query: { include: "personsNames" } }} />,
  },
  { path: "/communication", exact: true, render: routeProps => <CommunicationPage {...routeProps} /> },
  { path: "/communication/:id", exact: false, render: routeProps => <CommunicationPage {...routeProps} /> },
  { path: "/tests", exact: false, render: routeProps => <TestsPage {...routeProps} /> },
  { path: "/collateral", exact: true, render: routeProps => <CollateralPage {...routeProps} /> },
  { path: "/collateral/:id", exact: true, render: routeProps => <CollateralPage {...routeProps} /> },
  { path: "/collaterals", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/private-equity-asset", exact: true, render: routeProps => <PrivateEquityAssetPage {...routeProps} /> },
  { path: "/private-equity-asset/:id", exact: true, render: routeProps => <PrivateEquityAssetPage {...routeProps} /> },
  { path: "/private-equity-assets", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/private-equity-operation/:id", render: routeProps => <PrivateEquityOperationPage {...routeProps} /> },
  { path: "/private-equity-operation", render: routeProps => <PrivateEquityOperationPage {...routeProps} /> },

  { path: "/financing-service", exact: true, render: routeProps => <FinancingServicePage {...routeProps} /> },
  { path: "/financing-service/:id", exact: true, render: routeProps => <FinancingServicePage {...routeProps} /> },
  { path: "/financing-services", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },

  { path: "/payment-files", exact: true, render: routeProps => <EntitiesPage {...{ ...routeProps, hideAddButton: true }} /> },
  { path: "/payment-file/:id", exact: true, render: routeProps => <PaymentFilePage {...routeProps} /> },
  { path: "/payment-file", exact: true, render: routeProps => <PaymentFilePage {...routeProps} /> },
  { path: "/payment-file-line/:id", exact: true, render: routeProps => <PaymentFileLinePage {...routeProps} /> },

  { path: "/object-storage-containers", exact: true, render: routeProps => <ObjectStorageContainers {...routeProps} /> },
  { path: "/object-storage-container/:containerName", exact: true, render: routeProps => <ObjectStorageContainer {...routeProps} /> },

  { path: "/key-values", exact: true, render: routeProps => <KeyValuesPage {...routeProps} /> },
  { path: "/themes", exact: true, render: routeProps => <ThemesPage {...routeProps} /> },
  { path: "/icons", exact: true, render: routeProps => <IconsPage {...routeProps} /> },

  { path: "/dtxns", exact: true, render: routeProps => <DtxnsPage {...routeProps} /> },

  { path: "/feature-flags", exact: true, render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/feature-flag", exact: true, render: routeProps => <FeatureFlagPage {...routeProps} /> },
  { path: "/feature-flag/:flagName", exact: true, render: routeProps => <FeatureFlagPage {...routeProps} /> },

  { path: "/notifications", exact: true, render: routeProps => <NotificationsPage {...routeProps} /> },

  { path: "/claims", render: routeProps => <EntitiesPage {...routeProps} /> },
  { path: "/claim", exact: true, render: routeProps => <ClaimPage {...routeProps} /> },
  { path: "/claim/:id", render: routeProps => <ClaimPage {...routeProps} /> },

  // This is a special route to preload the bare app
  // (without loading extra data related to a particular page or configuration)
  // to speed up its usage afterwards.
  { path: "/app-preload", exact: true, render: () => <></> },
]

export { pagesLayoutRoutes, rootRoutes }
